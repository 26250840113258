import axios, { AxiosInstance } from "axios";

export class Tigo {
  static http: AxiosInstance;
  static baseURL = process.env.VUE_APP_TIGO_BACK;

  constructor() {
    Tigo.http = axios.create({
      baseURL: Tigo.baseURL,
    });
  }
}

const tigo = new Tigo();

export default tigo;
