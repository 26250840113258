import { Tigo } from "../Tigo";

const defaultEndpoint = "/api/send-convertia-medios.php"
const ACTIONS = {
  PYMES_B2B: process.env.VUE_APP_TIGO_ENDPOINT || defaultEndpoint,
};

export function create(body: any) {
  const newBody = JSON.parse(JSON.stringify(body));
  newBody.medio = process.env.VUE_APP_TIGO_MEAN;
  newBody.oferta = process.env.VUE_APP_TIGO_OFFER;
  newBody.nombre = body.name;
  newBody.celular = body.phone;
  delete newBody.name;
  delete newBody.phone;

  const formData = new FormData();
  for(const key in newBody){
    formData.append(key, newBody[key]);
  }
  return Tigo.http.post(ACTIONS.PYMES_B2B, formData);
}
