import { Gondor } from "../Gondor";

const ACTIONS = {
  LEAD: "/lead",
};

export function create(body: any) {
  return Gondor.http.post(ACTIONS.LEAD, body);
}
export function destroy(leadId?: string) {
  return Gondor.http.delete(`${ACTIONS.LEAD}/${leadId}`);
}
