
import { ref, defineComponent } from "vue";
import Swal from "sweetalert2";
import { useGtm } from "vue-gtm";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import useVuelidate from "@vuelidate/core";
import InputMask from "primevue/inputmask";
import * as LeadServices from "../api/helpers/Lead";
import * as EmtelcoServices from "../api/helpers/Emtelco";
import * as ApplicationServices from "../api/helpers/Application";
import * as FacebookServices from "../api/helpers/Facebook";
import { helpers, required, numeric, minLength } from "@vuelidate/validators";
import { getCurrentInstance } from "vue";

export default defineComponent({
  name: "Home",
  components: {
    Card,
    Button,
    InputText,
    InputMask,
  },
  setup() {
    var loading = ref(false);
    const f = ref({
      name: "",
      phone: "",
    });

    const alpha = helpers.regex(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ-\s]*$/);

    const rules = {
      name: { required, minLength: minLength(3), alpha },
      phone: { required, numeric, minLength: minLength(10) },
    };

    const submitted = ref(false);
    const v$ = useVuelidate(rules, f);

    const leadId = ref(undefined);

    const resetForm = () => {
      f.value.name = "";
      f.value.phone = "";
      submitted.value = false;
      loading.value = false;
      leadId.value = undefined;
    };
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;

    return {
      f,
      v$,
      submitted,
      loading,
      toastTime,
      leadId,
      resetForm,
    };
  },
  mounted() {
    FacebookServices.pageView();
    sessionStorage.clear();
    localStorage.clear();
  },
  methods: {
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
        leadId: this.leadId,
      });
    },
    register(isFormValid: boolean) {
      this.submitted = true;
      if (isFormValid) {
        this.loading = true;
        this.createLead();
      }
    },
    async createLead() {
      const body = {
        phone: this.f.phone,
        name: this.f.name,
        id: undefined
      };
      try {
        var emtelcoResponse = await EmtelcoServices.create(body);
        emtelcoResponse.data = emtelcoResponse.data.replace("Registro Correcto","");
        emtelcoResponse.data = emtelcoResponse.data.replace("200","");
        emtelcoResponse.data = JSON.parse(emtelcoResponse.data);
        if (!emtelcoResponse.data.status) {
          this.$toast.add({
            severity: "error",
            summary: emtelcoResponse.data.error,
            life: this.toastTime,
          });
          this.loading = false;
          return;
        }
        const {
          data: {
            data: { lead, application, newEmail },
          },
        } = await LeadServices.create(body);
        this.leadId = lead.id;
        const applicationBody = {
          completed: true,
          termsAndConditions: true,
          privacyNotice: true,
        };
        await ApplicationServices.update(applicationBody, application.id);
        this.triggerEvent();
        FacebookServices.lead();
        Swal.fire(
          "Muchas gracias",
          "Su solicitud fue enviada con éxito. Uno de nuestros asesores se comunicará desde la línea (034) 431 7000."
        );
        this.resetForm();
      } catch (error) {
        if (this.leadId) LeadServices.destroy(this.leadId);
        this.loading = false;
        const err =
          error.response && error.response.data
            ? error.response.data.message || error.response.data.Mensaje
            : error.toJSON().message || error;
        this.$toast.add({
          severity: "error",
          summary: err,
          life: this.toastTime,
        });
      }
    },
  },

  data() {
    return {
      styleButton: {
        backgroundColor: "#00377D",
        border: "1px solid #00377D",
      },
      cardRounded: {
        borderRadius: "10px",
        padding: "1rem",
      },
    };
  },
});
