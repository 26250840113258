<template>
  <div>
    <Toast position="top-right" :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }" />
    <Menubar>
      <template #start>
        <a href="https://www.tigo.com.co/empresas" class="ml-5">
          <img alt="logo" src="./assets/images/home-logo.png" height="70" class="p-mr-2" />
        </a>
      </template>
    </Menubar>
    <router-view class="view" />
    <div class="bg-blue overflow-hidden py-4 text-center">
      <div class="col-sm-12">
        <p class="p px-2">
          © Tigo Colombia {{ year }}. Servicios móviles prestados por Colombia Móvil S.A. E.S.P. | Servicios fijos
          prestados por UNE EPM Telecomunicaciones S.A. Todos los derechos reservados
        </p>
      </div>
      <div class="col-sm-12">
        <p class="p px-2">
          <!-- <a href="https://www.tigo.com.co/legal-y-regulatorio/politicas-tigo#politicas-tigo-terminos-y-condiciones-de-la-pagina-web"
            target="_blank"> -->
          Términos y condiciones
          <!-- </a> -->
          |
          <!-- <a href="https://www.tigo.com.co/legal-y-regulatorio/politica-de-datos-personales#politicas-de-datos-personales-y-de-seguridad-politica-de-proteccion-de-datos-personales"
            target="_blank"> -->
          Política de protección de datos personales
          <!-- </a> -->
        </p>
      </div>
      <div class="col-sm-12">
        <p class="p px-2 pt-3">
          <a href="https://www.facebook.com/Tigo.Col" target="_blank" class="text-white">
            <i class="fab fa-facebook px-2"></i>
          </a>
          <a href="https://twitter.com/Tigo_Colombia" target="_blank" class="text-white">
            <i class="fab fa-twitter px-2"></i>
          </a>
          <a href="https://www.instagram.com/tigocolombia/" target="_blank" class="text-white">
            <i class="fab fa-instagram px-2"></i>
          </a>
          <a href="https://www.youtube.com/user/Tigocolombiaoficial" target="_blank" class="text-white">
            <i class="fab fa-youtube px-2"></i>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  components: {
    Menubar: Menubar,
    Toast: Toast,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE
    };
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    return {
      toast,
      year,
    };
  },
};
</script>

<style>
.list-footer a {
  color: white !important;
}

.list-footer {
  list-style-type: none;
  text-align: left;
}

.list {
  color: #FFBD00;
  font-size: 12px;
}

.p {
  color: white;
  font-size: 14px;
  margin: 1em;
}

.bg-blue {
  background-color: #00377b;
}

i {
  font-size: 19px;
}

.view {
  width: 100%;
}

.p-menubar {
  z-index: 100;
  width: 100%;
  top: 0;
  background-color: #00377b !important;
  border-color: #00377b !important;
  border-radius: 0 !important; 
}

.p-menubar .p-menubar-button {
  position: absolute;
  right: 5%;
  display: none !important;
}

.p-menubar .p-menubar-button .pi {
  font-size: 2rem;
}

.p-menubar .p-menubar-root-list {
  position: absolute;
  right: 0px;
}

@media (min-width: 1825px) {
  .footer {
    padding: 0 25rem;
  }
}

.p-fb {
  margin-top: 0;
}
</style>
